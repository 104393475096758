<template>
  <div>
    <!-- 编辑记录 -->
    <div class="content1">
      <div class="all">
        <div class="table" >
          <!-- <el-button size="small" @click="backtrack">返回</el-button> -->
          <!--数据表格-->
          <el-table
              ref="tableList"
              :data="buildings"
              style="width: 100%; margin: 15px 0"
              border
              height="calc(100vh - 183px)"
              :stripe="true"
              :header-cell-style="{
              color: '#333',
              fontFamily: 'MicrosoftYaHeiUI',
              fontSize: '14px',
              fontWeight: 900,
              background: '#f8f8f9',
              textAlign:'left',
            }"
          >
            <el-table-column type="index" label="序号" width="70"  :index="((searchBox.current-1)*searchBox.size)+1"> </el-table-column>
            <el-table-column prop="createBy" label="编辑人"  show-overflow-tooltip></el-table-column>
            <el-table-column prop="createTime" label="编辑时间"  show-overflow-tooltip></el-table-column>
            <el-table-column prop="createBy" label="编辑内容" show-overflow-tooltip>
              <template slot-scope="{ row }">
                <el-button
                    type='primary'
                    size="small"
                    style=" cursor: pointer"
                    @click="examine(row)"
                >
                  查看
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              :page-sizes="[10, 30, 50, 100]"
              :page-size="searchBox.size"
              :current-page="searchBox.current"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import  {updatePageList} from '../../../RequestPort/peopleUser/people'
export default {
  name: "lockout",
  data(){
    return{
      // 查询条件
      searchBox:{
        current:1,
        size: 100,
      },
      total:null,
      // 列表数据
      buildings: [
        {
          addressName: '',
          companyId: '',
          addressAttr: '',
          floorType: '',
          buildingCategoryId: '',
          generalStatus: '',
          id:0
        }
      ],
    }
  },
  mounted() {
    this.loadList(this.searchBox)
  },
  methods:{
    /**
     * 返回
     */
    backtrack(){
      this.$router.back(-1)
    },
    /**
     * 更换条数
     */
    handleSizeChange(val){
      this.searchBox.current = 1
      this.searchBox.size = val
      this.loadList(this.searchBox)
    },
    /**
     * 更换页码
     */
    handleCurrentChange(val){
      this.searchBox.current = val
      this.loadList()
    },
    /**
     * 获取列表
     */
    loadList(obj){
      if(obj){
        this.searchBox.current = 1
      }
      this.searchBox.userId = this.$route.query.userId
      updatePageList(this.searchBox).then(res=>{
        this.buildings = res.data.records;
        this.total = res.data.total;
      })
    },
    /**
     * 查看
     */
    examine(row){
      this.$router.push({
        path: '/home/record/peopleCompileUser',
        query: { id: row.id },
      });
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-upload--picture-card{width:100px;height:100px;line-height:100px}
::v-deep .el-upload-list--picture-card .el-upload-list__item{width:100px;height:100px;line-height:100px}
.detil-code{
  padding-left: 10px;
  font-size: 16px;
  height: 26px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code:before{
  content: '';
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007AFF;
  top: 0;
  left: 0;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.SearchBtn{
  margin-left: 10px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
.el-radio{
  margin-right:0;
}
.el-form-item{
  margin-bottom:0;
}
</style>